import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AppStateService } from '../../../service/app-state.service';
import { NodeType } from '../../../constants/node-type';
import { WimTreeNode } from '../../../ model/wim-tree.node';
import { ConsoleLoggerService } from '../../../service/console-logger.service';
import { CantaaMessageService } from '../../../service/cantaa-message.service';
import { ToolCategoryService } from '../../../service/tool-category.service';
import { ToolCategoryDetailItem } from '../../../ model/tool-category-detail.model';
import { CantaaErrorHandlerService } from '../../../service/cantaa-error-handler.service';
import { ToolCategoryListItem } from '../../../ model/tool-category-list-item.model';
import { ToolType } from '../../../ model/tool-type.model';
import { ToolTypeService } from '../../../service/tool-type.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { JsonPipe } from '@angular/common';
import { ToolConstants } from '../../../constants/tool-constants';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteHelper } from '../../../service/helper/route-helper';
import { Permission } from '../../../constants/Permission';
import { HasPermissionDirective } from '../../../directive/has-permission.directive';
import { LoggedUserService } from '../../../service/logged-user.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'wim-tool-category-detail',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelect,
    MatOption,
    MatInput,
    MatButton,
    JsonPipe,
    HasPermissionDirective,
    TranslateModule
  ],
  templateUrl: './tool-category-detail.component.html',
  styleUrl: './tool-category-detail.component.scss'
})
export class ToolCategoryDetail implements OnInit, OnDestroy {
  protected readonly Permission = Permission;
  protected readonly ToolConstants = ToolConstants;
  parentCategories: ToolCategoryListItem[] = [];
  selectedCategory: ToolCategoryListItem | null = null;
  toolTypes: ToolType[] = [];


  detailForm = this.fb.group({
    id: this.fb.control<number | null>({ value: null, disabled: true }),
    parentCategory: this.fb.control<ToolCategoryListItem | null>({ value: null, disabled: true }),
    name: this.fb.control<string | null>({ value: null, disabled: false }, Validators.required),
    levelType: ({ value: '', disabled: true }),
    toolType: this.fb.control<ToolType | null>(null),
  });

  onSelectNodeSubscription!: Subscription;

  constructor(private fb: FormBuilder,
              private appStateService: AppStateService,
              private toolCategoryService: ToolCategoryService,
              private toolTypeService: ToolTypeService,
              private messageService: CantaaMessageService,
              private log: ConsoleLoggerService,
              private errorHandler: CantaaErrorHandlerService,
              private route: ActivatedRoute,
              private router: Router,
              private loggedUserService: LoggedUserService) {
  }

  async ngOnInit() {
    if (RouteHelper.isInNewState(this.route)) {
      await this.initFormForNewEntry();
    }

    this.onSelectNodeSubscription = this.appStateService.getSelectedNode$()
      .subscribe(async selectedNode => {
        if (!selectedNode.id || selectedNode.nodeType != NodeType.TOOL_CATEGORY) {
          return;
        }
        await this.initFormForEdit(selectedNode);
      });

    if (!this.loggedUserService.hasPermission(Permission.CATEGORY_EDIT)) {
      this.detailForm.disable();
    }
  }

  private async initFormForNewEntry() {
    const parentId = (this.appStateService.selectedNodeValue?.parent as WimTreeNode)?.id ?? null;

    this.parentCategories = await this.fetchToolCategories(parentId);
    let parentCategory = this.parentCategories.find(c => c.id === parentId) ?? null;

    let levelType = await this.getToolCategoryLevelTypeByParentCategoryId(parentId);

    if (levelType.levelType === ToolConstants.LEVEL_TYPE.TOOL) {
      this.toolTypes = await this.toolTypeService.getToolTypes();
      this.detailForm.controls.toolType.addValidators(Validators.required);
    } else {
      this.detailForm.controls.toolType.removeValidators(Validators.required);
    }

    this.detailForm.patchValue(
      {
        parentCategory: parentCategory,
        levelType: levelType.levelType
      }
    );
  }

  private async initFormForEdit(selectedNode: WimTreeNode) {
    const detail = await this.toolCategoryService.getToolCategoryDetail(selectedNode.id!);
    this.parentCategories = await this.fetchToolCategories(detail.parentId);

    let parentCategory = this.parentCategories.find(c => c.id === detail.parentId) ?? null;
    this.selectedCategory = parentCategory;

    let toolType: ToolType | null = null;

    if (detail.levelType === ToolConstants.LEVEL_TYPE.TOOL) {
      this.toolTypes = await this.toolTypeService.getToolTypes();
      toolType = this.toolTypes.find(t => t.id === detail.toolTypeId) ?? null;
      this.detailForm.controls.toolType.addValidators(Validators.required);
    } else {
      this.detailForm.controls.toolType.removeValidators(Validators.required);
    }

    if (this.parentCategories.length > 0) {
      this.detailForm.controls.parentCategory.enable();
    } else {
      this.detailForm.controls.parentCategory.disable();
    }

    this.detailForm.setValue(
      {
        id: detail.id,
        parentCategory: parentCategory,
        name: detail.name,
        levelType: detail.levelType,
        toolType: toolType
      });
  }

  private async fetchToolCategories(parentId: number | null) {
    try {
      return await this.toolCategoryService.findAllWithSameLevelType(parentId);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  private async getToolCategoryLevelTypeByParentCategoryId(parentId: number | null) {
    try {
      return await this.toolCategoryService.getToolCategoryLevelTypeByParentCategoryId(parentId);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  ngOnDestroy(): void {
    this.onSelectNodeSubscription?.unsubscribe();
  }

  async onSave() {
    this.log.debug('on save', this.detailForm.value);

    const toolCategoryDetailItem = {
      id: this.detailForm.getRawValue().id,
      parentId: this.detailForm.getRawValue().parentCategory?.id,
      name: this.detailForm.value.name,
      levelType: this.detailForm.value.levelType,
      toolTypeId: this.detailForm.value.toolType?.id
    } as ToolCategoryDetailItem

    this.log.debug('on save toolCategoryDetailItem', toolCategoryDetailItem);

    try {
      await this.toolCategoryService.saveToolCategoryDetail(toolCategoryDetailItem);
      this.messageService.success('SAVE.SUCCESS');
      if (RouteHelper.isInNewState(this.route)) {
        await RouteHelper.navigateToGrid(this.route, this.router);
      }
    } catch (e) {
      this.errorHandler.handleError(e, 'SAVE.FAILED');
    }

  }

  async onCancel() {
    if (this.detailForm.getRawValue().id == null) {
      this.detailForm.reset();
      return;
    }
    await this.initFormForEdit(this.appStateService.selectedNodeValue);
  }

}
