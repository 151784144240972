import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';
import { ToastModule } from 'primeng/toast';
import { MainToolbar } from './components/main-toolbar/main-toolbar.component';
import { filter } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { LoggedUserService } from './service/logged-user.service';
import { MatDrawer, MatDrawerContainer, MatSidenavModule } from '@angular/material/sidenav';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { MatButton } from '@angular/material/button';
import { LanguageMarketSelectorComponent } from './components/language-market-selector/language-market-selector.component';
import { MatIcon } from '@angular/material/icon';
import { MatDivider } from '@angular/material/divider';
import { MatMenu, MatMenuItem } from '@angular/material/menu';
import { SidenavItemsComponent } from './components/sidenav-items/sidenav-items.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { FilterMatchMode, FilterService, PrimeNGConfig } from 'primeng/api';
import { GridFilterConstants } from './constants/grid-filter-constants';
import { DateAdapter } from '@angular/material/core';


@Component({
  selector: 'wim-root',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    RouterOutlet,
    ToastModule,
    MainToolbar,
    MatDrawerContainer,
    MatFormField,
    MatOption,
    MatSelect,
    MatButton,
    MatDrawer,
    MatSidenavModule,
    LanguageMarketSelectorComponent,
    MatIcon,
    RouterLink,
    MatDivider,
    MatMenuItem,
    MatMenu,
    SidenavItemsComponent,
    LoadingIndicatorComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'wim-angular';

  constructor(private oauthService: OAuthService,
              private loggedUserService: LoggedUserService,
              private filterService: FilterService,
              private primengConfig: PrimeNGConfig,
              dateAdapter: DateAdapter<Date>) {
    
    dateAdapter.getFirstDayOfWeek = () => 1;

    console.log('used environment: ' + environment.environment);
    // this.fetchLoggedUser()

    this.registerGridFilters();
  }

  private registerGridFilters() {
    // https://primeng.org/filterservice#customconstraints

    this.filterService.register(GridFilterConstants.IS_NOT_EMPTY, (value: any, filter: any): boolean => {
      // this it place holder because we are doing filtering on backend
      return true;
    });

    this.filterService.register(GridFilterConstants.IS_EMPTY, (value: any, filter: any): boolean => {
      // this it place holder because we are doing filtering on backend
      return true;
    });
  }

// private fetchLoggedUser() {
  //   // loggedUserService.fetchLoggedUser();
  //   // await loggedUserService.fetchLoggedUserAsync();
  //
  //   this.oauthService.events
  //     .pipe(filter(e => ['token_received'].includes(e.type)))
  //     .subscribe(e => {
  //       this.oauthService.loadUserProfile().then((userProfile) => {
  //         console.log('user profile from token_received event', userProfile);
  //       })
  //       this.loggedUserService.fetchLoggedUser();
  //     });
  // }

  async ngOnInit() {
    this.specifyGridFilters();

    // await this.loggedUserService.fetchLoggedUserAsync();
    this.oauthService.events
      .pipe(filter(e => ['token_received'].includes(e.type)))
      .subscribe(async e => {
        this.oauthService.loadUserProfile().then((userProfile) => {
          console.log('user profile from token_received event', userProfile);
        })
        await this.loggedUserService.fetchLoggedUserAsync();
      });
  }


  private specifyGridFilters() {
    this.primengConfig.filterMatchModeOptions = {
      text: [
        FilterMatchMode.STARTS_WITH, FilterMatchMode.CONTAINS, FilterMatchMode.NOT_CONTAINS, FilterMatchMode.ENDS_WITH,
        FilterMatchMode.EQUALS, FilterMatchMode.NOT_EQUALS, GridFilterConstants.IS_NOT_EMPTY, GridFilterConstants.IS_EMPTY
      ],
      numeric: [
        FilterMatchMode.EQUALS, FilterMatchMode.NOT_EQUALS, FilterMatchMode.LESS_THAN,
        FilterMatchMode.LESS_THAN_OR_EQUAL_TO, FilterMatchMode.GREATER_THAN, FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        GridFilterConstants.IS_NOT_EMPTY, GridFilterConstants.IS_EMPTY
      ],
      date: [
        FilterMatchMode.DATE_IS, FilterMatchMode.DATE_IS_NOT, FilterMatchMode.DATE_BEFORE, FilterMatchMode.DATE_AFTER,
        GridFilterConstants.IS_NOT_EMPTY, GridFilterConstants.IS_EMPTY
      ]
    };
  }
}
