import { Component, OnInit } from '@angular/core';
import { PrimeTemplate } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { TranslateModule } from '@ngx-translate/core';
import { SystemJob } from '../../../../ model/system-job.model';
import { SystemJobService } from '../../../../service/system-job.service';
import { MatIcon } from '@angular/material/icon';
import { TooltipModule } from 'primeng/tooltip';
import { MatFabButton, MatIconButton, MatMiniFabButton } from '@angular/material/button';
import { DatePipe } from '@angular/common';
import { LoadingService } from '../../../../service/loading.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'wim-system-job',
  standalone: true,
  imports: [
    PrimeTemplate,
    TableModule,
    TranslateModule,
    MatIcon,
    TooltipModule,
    MatIconButton,
    DatePipe,
    MatFabButton,
    MatMiniFabButton
  ],
  templateUrl: './system-job-list.component.html',
  styleUrl: './system-job-list.component.scss'
})
export class SystemJobListComponent implements OnInit {
  systemJobs: SystemJob[] = [];

  constructor(private systemJobService: SystemJobService,
              private loadingService: LoadingService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute) {
  }

  async ngOnInit() {
    await this.fetchJobs();
  }

  async fetchJobs() {
    this.systemJobs = await this.systemJobService.getSystemJobs();
  }

  async runJob(jobId: string) {
    this.loadingService.loadingOn();

    this.systemJobService.runSystemJob(jobId).then(r => {
      this.fetchJobs();
    });

    setTimeout(() => {
      try {
        this.fetchJobs();
      } finally {
        this.loadingService.loadingOff();
      }
    }, 1500);
  }

  public async toEditPage(jobId: string) {
    await this.router.navigate([jobId], { relativeTo: this.activatedRoute });
  }

}
