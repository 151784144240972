import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { ToolInventoryListItem } from '../../../ model/tool-inventory-list-item.model';
import { TableModule } from 'primeng/table';
import { NodeType } from '../../../constants/node-type';
import { AppStateService } from '../../../service/app-state.service';
import { InventoryService } from '../../../service/inventory.service';
import { Subscription } from 'rxjs';
import { CantaaErrorHandlerService } from '../../../service/cantaa-error-handler.service';
import {ToolLocationDocumentService} from "../../../service/tool-location-document.service";
import {ToolDocumentService} from "../../../service/tool-document.service";
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'wim-tool-category-inventory',
  standalone: true,
  imports: [
    MatButton,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    TableModule,
    TranslateModule
  ],
  templateUrl: './tool-location-inventory.component.html',
  styleUrl: './tool-location-inventory.component.scss'
})
export class ToolLocationInventoryComponent implements OnInit, OnDestroy {

  inventory: ToolInventoryListItem[] = [];
  onSelectNodeSubscription!: Subscription;


  constructor(private appStateService: AppStateService,
              private toolInventoryService: InventoryService,
              protected toolDocumentService: ToolDocumentService,
              private errorHandler: CantaaErrorHandlerService) {
  }

  async ngOnInit() {
    // if (ExplorerEventType.NEW_ITEM === this.parameters()) {
    //   await this.initFormForNewEntry();
    // }

    this.onSelectNodeSubscription = this.appStateService.getSelectedNode$()
      .subscribe(async selectedNode => {
        if (!selectedNode.id || selectedNode.nodeType != NodeType.TOOL_LOCATION) {
          return;
        }
        await this.fetchToolLocationInventory(selectedNode.id);
      });
  }

  ngOnDestroy(): void {
    this.onSelectNodeSubscription?.unsubscribe();
  }

  private async fetchToolLocationInventory(id: number) {
    try {
      this.inventory = await this.toolInventoryService.getToolLocationInventory(id);
      this.inventory.forEach(inventory => {
        this.toolDocumentService.getDefaultPhotoScaled35x35AndRender(inventory.toolId);
      });
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  splitDataByLocation(): ToolInventoryListItem[][] {
    const locations = new Set(this.inventory.map(item => item.locationPretty));
    const tablesData: ToolInventoryListItem[][] = [];
    locations.forEach(location => {
      const dataForLocation = this.inventory.filter(item => item.locationPretty === location);
      tablesData.push(dataForLocation);
    });
    return tablesData;
  }

}
