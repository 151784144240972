<div class="container ">

  <div class="row">
    <p-table
      #dt
      dataKey="id"
      [value]="tools"
      [sortMode]="'multiple'"
      [scrollable]="true" scrollHeight="var(--explorer-grid-height)"
      [tableStyle]="{ 'min-width': '50rem' }"
      styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
    >
      <ng-template pTemplate="header">
        <tr>
          <th id="defaultpicture" style="width:1px" pResizableColumn>
          </th>
          <th id="name" style="min-width:10rem" pSortableColumn="name" pResizableColumn>
            {{ 'NAME' | translate }}
            <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th id="toolType" style="min-width:10rem" pSortableColumn="toolType" pResizableColumn>
            {{ 'TOOLTYPE' | translate }}
            <p-sortIcon field="toolType"></p-sortIcon>
          </th>
          <th id="rfid" style="min-width:10rem" pSortableColumn="rfid" pResizableColumn>
            {{ 'RFID' | translate }}
            <p-sortIcon field="rfid"></p-sortIcon>
          </th>
          <th id="serialNumber" style="min-width:12rem" pSortableColumn="serialNumber" pResizableColumn>
            {{ 'SERIALNUMBER' | translate }}
            <p-sortIcon field="serialNumber"></p-sortIcon>
          </th>
          <th id="manufacturerName" style="min-width:12rem" pSortableColumn="manufacturerName" pResizableColumn>
            {{ 'MANUFACTURER.MANUFACTURER' | translate }}
            <p-sortIcon field="manufacturerName"></p-sortIcon>
          </th>
          <th id="locationPretty" style="min-width:13rem" pSortableColumn="locationPretty" pResizableColumn>
            {{ 'LOCATION.LOCATION' | translate }}
            <p-sortIcon field="locationPretty"></p-sortIcon>
          </th>
          <th id="supplierPretty" style="min-width:10rem" pSortableColumn="supplierPretty" pResizableColumn>
            {{ 'SUPPLIER' | translate }}
            <p-sortIcon field="supplierPretty"></p-sortIcon>
          </th>
          <th id="lastTransactionDate" style="min-width:16rem" pSortableColumn="lastTransactionDate" pResizableColumn>
            {{ 'DATE.LAST_TRANSACTION_DATE' | translate }}
            <p-sortIcon field="lastTransactionDate"></p-sortIcon>
          </th>
          <th id="fromLocationPretty" style="min-width:16rem" pSortableColumn="fromLocationPretty" pResizableColumn>
            {{ 'ORIGIN.ACTUAL' | translate }}
            <p-sortIcon field="fromLocationPretty"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-tool>
        <tr>
          <td style="padding: 0px;"><img id="{{ toolDocumentService.generateImgElementId4Tool(tool.id) }}" class="img-defaultphoto-icon" src=""></td>
          <td>{{ tool.name }}</td>
          <td>{{ tool.toolType }}</td>
          <td>{{ tool.rfid }}</td>
          <td>{{ tool.serialNumber }}</td>
          <td>{{ tool.manufacturerName }}</td>
          <td>{{ tool.locationPretty }}</td>
          <td>{{ tool.supplierPretty }}</td>
          <td>{{ tool.lastTransactionDate }}</td>
          <td>{{ tool.fromLocationPretty }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

</div>
