<div class="card p-input-filled" style="padding: 20px; min-height: 80vh">

  <p-table
    #dt
    dataKey="id"
    [value]="locationTypes"
    [lazy]="true"
    [resizableColumns]="true" columnResizeMode="expand"
    (onLazyLoad)="fetchDataViaTableComponent($event)"
    [paginator]="true"
    [rows]="gridMeta.lazyLoadMeta?.rows?? 10"
    [rowsPerPageOptions]="[10, 20, 50]"
    [paginatorDropdownAppendTo]="'body'"
    [totalRecords]="totalRecords"
    [sortMode]="'multiple'"
    [multiSortMeta]="gridMeta.lazyLoadMeta?.multiSortMeta"
    [filters]="gridMeta.filters!"
    [scrollable]="true" scrollHeight="var(--explorer-grid-height)"
    editMode="row"
    [tableStyle]="{width: 'max-content'}"
    styleClass="p-datatable-sm quick-grid strikethrough-able-grid grid-shadow p-datatable-gridlines"
  >

    <ng-template id="simple-header" pTemplate="header">
      @if (editMode) {
        <tr>
          <th id="header_code_simple" class="p-dummy-element">Code</th>
          <th id="header_name_simple" class="p-dummy-element">Name</th>
          <th id="header_mobileLocation_simple" class="p-dummy-element">Mobile</th>
          <th id="header_action_simple" class="p-dummy-element"></th>
        </tr>
      } @else {
        <tr>
          <th id="header_code" pSortableColumn="code" pResizableColumn>Code
            <p-sortIcon field="code"></p-sortIcon>
            <p-columnFilter type="text" field="code" display="menu" [showOperator]="false"></p-columnFilter>
          </th>
          <th id="header_name" pSortableColumn="name" pResizableColumn>Name
            <p-sortIcon field="name"></p-sortIcon>
            <p-columnFilter type="text" field="name" display="menu" [showOperator]="false"></p-columnFilter>
          </th>
          <th id="header_mobile" pSortableColumn="mobileLocation" pResizableColumn>Mobile
            <p-sortIcon field="mobileLocation"></p-sortIcon>
            <p-columnFilter type="boolean" field="mobileLocation" display="menu" [showOperator]="false"></p-columnFilter>
          </th>
          <th id="header_action" class="p-dummy-element"></th>
        </tr>
      }
    </ng-template>
    <ng-template pTemplate="body" let-item let-editing="editing" let-ri="rowIndex">
      <tr [pEditableRow]="item">
        <td [class]="getTdClass(item)">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <mat-form-field>
                <input matInput type="text" [(ngModel)]="item.code">
              </mat-form-field>
            </ng-template>
            <ng-template pTemplate="output">
              {{ item.code }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td [class]="getTdClass(item)">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <mat-form-field>
                <input matInput type="text" [(ngModel)]="item.name">
              </mat-form-field>
            </ng-template>
            <ng-template pTemplate="output">
              {{ item.name }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td [class]="getTdClass(item)">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <mat-checkbox [(ngModel)]="item.mobileLocation" color='primary'></mat-checkbox>
            </ng-template>
            <ng-template pTemplate="output">
              {{ item.mobileLocation ? 'yes' : 'no' }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          <div class="" style="float: right">
            @if (editing) {
              <button mat-icon-button color="primary" pSaveEditableRow (click)="onRowEditSave(item)">
                <mat-icon>done</mat-icon>
              </button>

              <button mat-icon-button pCancelEditableRow (click)="onRowEditCancel(item, ri)">
                <mat-icon>close</mat-icon>
              </button>

            } @else {
              <button [disabled]="editMode || !item.active" mat-icon-button color="primary"
                      pInitEditableRow (click)="onRowEditInit(item)"
                      *hasPermission="Permission.TABLE_EDIT">
                <mat-icon>edit</mat-icon>
              </button>

              <button type="button" mat-icon-button (click)="onUpdateStatus(item)"
                      *hasPermission="Permission.TABLE_DELETE">
                @if (item.active) {
                  <mat-icon>delete</mat-icon>
                } @else {
                  <mat-icon fontIcon="restart_alt"></mat-icon>
                }
              </button>
            }
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <form [formGroup]="createNewForm" autocomplete="off" (ngSubmit)="onCreateNew()"
        *hasPermission="Permission.TABLE_CREATE">
    <div class="row">
      <div class="col-2">
        <mat-form-field class="w-100">
          <mat-label>Code</mat-label>
          <input id="code" matInput formControlName="code">
        </mat-form-field>
      </div>
      <div class="col-2">
        <mat-form-field class="w-100">
          <mat-label>Name</mat-label>
          <input id="name" matInput formControlName="name">
        </mat-form-field>
      </div>
      <div class="col-2">
        <mat-checkbox formControlName="mobileLocation" color='primary'>Mobile location?</mat-checkbox>
      </div>

      <div class="col-2">
        <button type="submit" [disabled]="!createNewForm.valid" mat-icon-button color="primary">
          <mat-icon>done</mat-icon>
        </button>

        <button type="button" mat-icon-button (click)="onCancel()">
          <mat-icon>close</mat-icon>
        </button>

      </div>
    </div>

  </form>
</div>
