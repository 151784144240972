import { Component, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { SnackbarService } from '../../../service/snackbar.service';
import { LoadingService } from '../../../service/loading.service';
import { HasAnyPermissionDirective } from '../../../directive/has-any-permission.directive';
import { CommonModule } from '@angular/common';
import { HasPermissionDirective } from '../../../directive/has-permission.directive';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormField, MatFormFieldModule, MatHint } from '@angular/material/form-field';
import { MatInput, MatLabel } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { ToolRfidValidator } from '../../../validator/tool-rfid.validator';
import { ToolService } from '../../../service/tool.service';
import { CantaaMessageService } from '../../../service/cantaa-message.service';
import { ToolDetailItem } from '../../../ model/tool-detail.model';
import { CantaaErrorHandlerService } from '../../../service/cantaa-error-handler.service';
import { FormErrorHelper } from '../../../service/helper/form-error-helper';
import { LoggedUserService } from '../../../service/logged-user.service';
import { LoggedUser } from '../../../ model/logged-user.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'wim-test-component',
  standalone: true,
  imports: [
    CommonModule,
    MatButton,
    MatIcon,
    MatLabel,
    RouterLink,
    HasAnyPermissionDirective,
    HasPermissionDirective,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatHint,
    TranslateModule,
    MatFormFieldModule,
  ],
  templateUrl: './test.component.html',
  styleUrl: './test.component.scss'
})
export class TestComponent implements OnInit {
  protected readonly FormErrorHelper = FormErrorHelper;

  detailForm = this.fb.group({
    name: ['', { validators: [Validators.required, Validators.minLength(3), Validators.maxLength(6)] }],
    serialNumber: ['', { validators: [Validators.pattern('[0-9]{3}[-][0-9]{3}')] }],
  });

  detailForm2 = this.fb.group({
    rfid: ['', {
      validators: [Validators.required],
      asyncValidators: [this.toolRfidValidator.validate.bind(this.toolRfidValidator)], updateOn: 'blur'
    }],
    note: (''),
  });

  detailForm3 = this.fb.group({
    rfid2: ['', {
      validators: [Validators.required]
    }],
    name2: (''),
  });

  constructor(private snackbarService: SnackbarService,
              private loadingService: LoadingService,
              private fb: FormBuilder,
              private toolRfidValidator: ToolRfidValidator,
              private toolService: ToolService,
              private messageService: CantaaMessageService,
              private errorHandler: CantaaErrorHandlerService,
              private loggedUserService: LoggedUserService,) {
  }

  loggedUser$: Observable<LoggedUser> | null = null;

  ngOnInit(): void {
    this.loggedUser$ = this.loggedUserService.loggedUser$;
  }

  toastTest() {
    this.snackbarService.openSuccessSnackBar('test', 'detail');
  }

  loadingOn() {
    this.loadingService.loadingOn();
  }

  loadingOff() {
    this.loadingService.loadingOff();
  }

  async onSave() {
    console.log('on save', this.detailForm.value);

    const toolDetailItem = {
      rfid: this.detailForm3.getRawValue().rfid2,
      name: this.detailForm3.getRawValue().name2,
    } as ToolDetailItem;

    console.log('on save toolDetailItem', toolDetailItem);
    this.detailForm3.markAsPristine();

    try {
      await this.toolService.saveToolDetailTest(toolDetailItem);
      this.messageService.success('SAVE.SUCCESS');
    } catch (e: any) {
      this.errorHandler.handleFormError(this.detailForm3, e, 'SAVE.FAILED');
    }
  }

  async onCancel() {
    this.detailForm3.reset();
  }

}
