import {AfterViewInit, ChangeDetectorRef, Component, inject, OnDestroy, viewChild} from '@angular/core';
import {Table, TableLazyLoadEvent} from 'primeng/table';
import {AppStateService} from '../../service/app-state.service';
import {WimTreeNode} from '../../ model/wim-tree.node';
import {Subscription} from 'rxjs';
import {ConsoleLoggerService} from '../../service/console-logger.service';
import {RouteHelper} from '../../service/helper/route-helper';
import {ActivatedRoute, Router} from '@angular/router';
import {NodeType} from '../../constants/node-type';
import {GridHelperService} from '../../service/helper/grid-helper.service';
import {GridRequest} from '../../ model/grid-request.model';
import {CollectionDto} from '../../ model/collection.model';
import {GridMetaService} from "../../service/grid-meta.service";
import {CantaaGridMeta} from "../../ model/GridMeta";

@Component({
  template: ''
})
export abstract class BaseNodeGridComponent<T> implements AfterViewInit, OnDestroy {

  protected appState = inject(AppStateService);
  protected log = inject(ConsoleLoggerService);
  protected gridHelperService = inject(GridHelperService);
  protected router = inject(Router);
  protected activatedRoute = inject(ActivatedRoute);
  protected gridMetaService = inject(GridMetaService);
  private cdRef = inject(ChangeDetectorRef);

  public dataTable = viewChild.required<Table>('dt');
  public gridItems: T[] = [];
  public selectedItem: T | null = null;

  public totalRecords: number = 0;
  public lastSelectedNode: WimTreeNode | null = null;
  private onSelectedNodeSubscription!: Subscription;
  protected nodeType = NodeType.TOOL_RELATION;

  gridMeta!: CantaaGridMeta;

  constructor() {
    this.initGridMeta();
  }

  private initGridMeta() {
    const gridIdentifier = this.getGridIdentifier();
    this.gridMeta = this.gridMetaService.getGridMeta(gridIdentifier, this.getDefaultSort(), this.getDefaultFilter());
    console.log(this.gridMeta);
  }

  private getGridIdentifier() {
    const selectedNode = this.appState.selectedNodeValue;
    return this.constructor.name + selectedNode.key!;
  }

  protected getDefaultSort() {
    return [{field: 'id', order: 1}];
  }

  protected getDefaultFilter() {
    return this.gridMetaService.defaultFilterFallback;
  }

  ngAfterViewInit(): void {
    this.onSelectedNodeSubscription = this.appState.getSelectedNode$()
      .subscribe(async selectedNode => {
        this.log.debug('selected node', selectedNode);
        if (this.needReloadAfterNodeChanged(selectedNode)) {

          if (this.lastSelectedNode && this.lastSelectedNode.nodeType === selectedNode.nodeType) {
            // this.forceRefresh();
            this.initGridMeta();
            this.cdRef.markForCheck();
          }

          await this.onNodeChange(selectedNode);
        }
        this.lastSelectedNode = selectedNode;
      });
  }

  public async onNodeChange(selectedNode: WimTreeNode) {
    let lazyLoadMetadata = this.dataTable().createLazyLoadMetadata();
    await this.fetchData(selectedNode, lazyLoadMetadata);
  }

  public needReloadAfterNodeChanged(selectedNode: WimTreeNode): boolean {
    if (
      // selected node is not empty
      selectedNode.key
      // last selected node and currentSelected node are differ
      && (this.lastSelectedNode == null || this.lastSelectedNode.key != selectedNode.key)
      && (selectedNode.nodeType == this.nodeType)
    ) {
      return true;
    }
    return false;
  }

  ngOnDestroy(): void {
    if (this.onSelectedNodeSubscription) {
      this.onSelectedNodeSubscription.unsubscribe();
    }
  }

  public async fetchDataViaTableComponent(event: TableLazyLoadEvent) {
    let selectedNode = this.appState.selectedNodeValue
    await this.fetchData(selectedNode, event);
  }

  public async fetchData(selectedNode: WimTreeNode, lazyLoadMetadata: TableLazyLoadEvent) {
    let gridRequest = this.gridHelperService.createGridRequest(lazyLoadMetadata);
    gridRequest.searchQuery = this.gridMeta?.searchQuery;

    let gridMeta: CantaaGridMeta = {
      lazyLoadMeta: lazyLoadMetadata,
      searchQuery: this.gridMeta?.searchQuery
    }

    this.gridMetaService.setGridMeta(this.getGridIdentifier(), gridMeta);


    const collection = await this.fetchGridItems(gridRequest, selectedNode);
    const treeItems = this.mapTreeItems(selectedNode, collection.items);

    this.appState.setCurrentTreeItems(treeItems);
    if (!selectedNode.parent) {
      selectedNode.expanded = true;
    }

    this.gridItems = collection.items;
    this.totalRecords = collection.totalNumberOfItems;
  }

  async fetchGridItems(gridRequest: GridRequest, selectedNode: WimTreeNode): Promise<CollectionDto<T>> {
    return {
      items: [],
      totalNumberOfItems: 0
    };
  }

  mapTreeItems(selectedNode: WimTreeNode, items: T[]): WimTreeNode[] {
    return [];
  }

  public async reFetchData() {
    const selectedNode = this.appState.selectedNodeValue;
    const lazyLoadMetadata = this.dataTable().createLazyLoadMetadata();
    await this.fetchData(selectedNode, lazyLoadMetadata);
  }

  public async onRowDblClick(event: any, treeNode: WimTreeNode) {
    if (treeNode) {
      this.appState.setSelectedNode(treeNode);
      await RouteHelper.navigateViaTreeNode(this.router, this.activatedRoute, treeNode);
    }
  }

  public async createNew() {
    await this.router.navigate(['new'], { relativeTo: this.activatedRoute });
  }

  async onSearch(searchQuery: string) {
    this.gridMeta.searchQuery = searchQuery;
    await this.reFetchData();
  }

}
