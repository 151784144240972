import { Component, OnDestroy, OnInit } from '@angular/core';
import { TableModule } from "primeng/table";
import { AppStateService } from "../../../service/app-state.service";
import { CantaaErrorHandlerService } from "../../../service/cantaa-error-handler.service";
import { NodeType } from "../../../constants/node-type";
import { WimTreeNode } from "../../../ model/wim-tree.node";
import { Subscription } from "rxjs";
import { ToolInspectionListItem } from '../../../ model/tool-inspection-list-item.model';
import { InspectionService } from '../../../service/inspection.service';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'wim-tool-detail-inspections',
  standalone: true,
  imports: [
    TableModule,
    TranslateModule
  ],
  templateUrl: './tool-detail-inspections.component.html',
  styleUrl: './tool-detail-inspections.component.scss'
})
export class ToolDetailInspectionsComponent implements OnInit, OnDestroy {

  onSelectNodeSubscription!: Subscription;
  inspections: ToolInspectionListItem[] = [];

  constructor(private appStateService: AppStateService,
              private inspectionService: InspectionService,
              private errorHandler: CantaaErrorHandlerService) {
  }

  async ngOnInit() {
    this.onSelectNodeSubscription = this.appStateService.getSelectedNode$()
      .subscribe(async selectedNode => {
        if (!selectedNode.id || selectedNode.nodeType != NodeType.TOOL) {
          return;
        }
        await this.fetchDetail(selectedNode);
      });
  }

  private async fetchDetail(selectedNode: WimTreeNode) {
    this.inspections = await this.fetchToolInspectionsByToolId(selectedNode.id!);
  }

  private async fetchToolInspectionsByToolId(id: number) {
    try {
      return await this.inspectionService.getToolInspectionsByTool(id);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  ngOnDestroy(): void {
    this.onSelectNodeSubscription?.unsubscribe();
  }
}
