<p-tree [value]="wimTreeNodes"
        class="w-full"
        [style]="{ height: '100%', width: '100%' }"
        selectionMode="single"
        [(selection)]="selectedNodeForUI"
        (onNodeExpand)="nodeExpand($event)"
        (onNodeCollapse)="nodeCollapse($event)"
        (onNodeSelect)="nodeSelect($event)"
        (onNodeUnselect)="nodeUnselect($event)"
        scrollHeight="flex">
  <ng-template let-node pTemplate="default">
    <div #nodeReference id="{{node.key}}" (dblclick)="doubleClick(node.key)">
      @if (node.highlighterClass) {
        <span [ngClass]="node.highlighterClass">&nbsp;</span>
      }
      {{ node.label }}
    </div>
  </ng-template>
</p-tree>

