import { Component } from '@angular/core';
import { TableModule } from "primeng/table";
import { FormBuilder } from "@angular/forms";
import { AppStateService } from "../../../service/app-state.service";
import { CantaaMessageService } from "../../../service/cantaa-message.service";
import { ConsoleLoggerService } from "../../../service/console-logger.service";
import { CantaaErrorHandlerService } from "../../../service/cantaa-error-handler.service";
import { NodeType } from "../../../constants/node-type";
import { WimTreeNode } from "../../../ model/wim-tree.node";
import { Subscription } from "rxjs";
import { LocationInventoryListItem } from "../../../ model/location-inventory-list-item.model";
import { InventoryService } from "../../../service/inventory.service";
import {ToolLocationDocumentService} from "../../../service/tool-location-document.service";
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'wim-tool-detail-inventory',
  standalone: true,
  imports: [
    TableModule,
    TranslateModule
  ],
  templateUrl: './tool-detail-inventory.component.html',
  styleUrl: './tool-detail-inventory.component.scss'
})
export class ToolDetailInventoryComponent {

  onSelectNodeSubscription!: Subscription;
  inventory: LocationInventoryListItem[] = [];

  constructor(private fb: FormBuilder,
              private appStateService: AppStateService,
              private toolInventoryService: InventoryService,
              private messageService: CantaaMessageService,
              private log: ConsoleLoggerService,
              protected toolLocationDocumentService: ToolLocationDocumentService,
              private errorHandler: CantaaErrorHandlerService) {

  }

  async ngOnInit() {
    this.onSelectNodeSubscription = this.appStateService.getSelectedNode$()
      .subscribe(async selectedNode => {
        if (!selectedNode.id || selectedNode.nodeType != NodeType.TOOL) {
          return;
        }
        await this.fetchDetail(selectedNode);
      });
  }

  private async fetchDetail(selectedNode: WimTreeNode) {
    const toolInventory = await this.fetchToolInventoryByToolId(selectedNode.id!);
    this.inventory = toolInventory.items;

    this.inventory.forEach(inventory => {
      this.toolLocationDocumentService.getDefaultPhotoScaled35x35AndRender(inventory.locationId);
    });
  }

  private async fetchToolInventoryByToolId(id: number) {
    try {
      return await this.toolInventoryService.getToolInventory(id);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  ngOnDestroy(): void {
    this.onSelectNodeSubscription?.unsubscribe();
  }

  onSave() {
    // this.log.debug('on save', this.detailForm.value);
    // this.messageService.error('Error', 'Not implemented');
  }

  onCancel() {
    // let selectedNodeValue = this.appStateService.selectedNodeValue;
    // this.fetchDetail(selectedNodeValue);
  }
}
