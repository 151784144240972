import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoggedUserService } from '../../service/logged-user.service';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatToolbar } from '@angular/material/toolbar';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconButton } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { MatTooltip } from '@angular/material/tooltip';
import { LanguageMarketSelectorComponent } from '../language-market-selector/language-market-selector.component';
import { ToolLocationDocumentService } from "../../service/tool-location-document.service";

@Component({
  selector: 'wim-main-toolbar',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MatToolbar,
    MatIcon,
    MatIconButton,
    MatButton,
    RouterLink,
    MatTooltip,
    LanguageMarketSelectorComponent
  ],
  templateUrl: './main-toolbar.component.html',
  styleUrl: './main-toolbar.component.scss'
})
export class MainToolbar implements OnInit {

  @Output()
  sidenavToggled: EventEmitter<any> = new EventEmitter();

  userName = '';
  email = '';

  constructor(private loggedUserService: LoggedUserService,
              private toolLocationDocumentService: ToolLocationDocumentService) {
  }

  ngOnInit() {
    this.loggedUserService.loggedUser$
      .subscribe(loggedUser => {
        this.userName = loggedUser.name;
        this.email = loggedUser.email;
      });


     this.toolLocationDocumentService.getDefaultPhotoScaled35x35_4Email2ImgEleId(this.email, 'img_toolbar_loggedUser');
  }

  toggle() {
    this.sidenavToggled.emit();
  }
}
