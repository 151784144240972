<mat-form-field class="the-dense-zone-5 mt-1" floatLabel="always">
  <mat-label>search</mat-label>
  <input matInput type="text" [(ngModel)]="searchQuery"
         (keydown.enter)="search()"
         [placeholder]="'SEARCH.SEARCH' | translate"
         onclick="this.select()">
  <!--  @if (searchQuery) {-->
    <button matSuffix mat-icon-button aria-label="search" (click)="search()">
      <mat-icon>search</mat-icon>
    </button>
  <!--  }-->
</mat-form-field>
