<div class="card p-input-filled full-height">
  <p-table
    #dt
    dataKey="id"
    [value]="gridItems"
    [lazy]="true"
    selectionMode="single"
    [(selection)]="selectedItem"
    [resizableColumns]="true" columnResizeMode="expand"
    (onLazyLoad)="fetchDataViaTableComponent($event)"
    [paginator]="true"
    [rows]="gridMeta.lazyLoadMeta?.rows ?? 20"
    [rowsPerPageOptions]="[10, 20, 50]"
    [paginatorDropdownAppendTo]="'body'"
    [totalRecords]="totalRecords"
    [sortMode]="'multiple'"
    [multiSortMeta]="defaultSort"
    [scrollable]="true" scrollHeight="var(--explorer-grid-height)"
    [tableStyle]="{ 'min-width': '50rem' }"
    [filters]="defaultFilter"
    styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
  >
    <ng-template pTemplate="header">
      <tr>
        <th *showIfDebugMode id="id" style="min-width:8rem" pSortableColumn="id" pResizableColumn>
          {{ 'Id' | translate }}
          <p-sortIcon field="id"></p-sortIcon>
          <p-columnFilter type="numeric" field="id" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
        <th id="locationPretty" style="min-width:10rem" pSortableColumn="locationPretty" pResizableColumn>
          {{ 'LOCATION.LOCATION' | translate }}
          <p-sortIcon field="locationPretty"></p-sortIcon>
          <p-columnFilter type="text" field="locationPretty" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
        <th id="dateFromFormatted" style="min-width:13rem" pSortableColumn="dateFromFormatted" pResizableColumn>
          {{ 'DATE.FROM' | translate }}
          <p-sortIcon field="dateFromFormatted"></p-sortIcon>
          <p-columnFilter type="date" field="dateFromFormatted" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
        <th id="dateUntilFormatted" style="min-width:16rem" pSortableColumn="dateUntilFormatted" pResizableColumn>
          {{ 'DATE.TO' | translate }}
          <p-sortIcon field="dateUntilFormatted"></p-sortIcon>
          <p-columnFilter type="date" field="dateUntilFormatted" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
        <th id="status" style="min-width:10rem" pSortableColumn="status" pResizableColumn>
          {{ 'STATUS' | translate }}
          <p-sortIcon field="status"></p-sortIcon>
          <p-columnFilter type="text" field="status" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
        <th id="employeePretty" style="min-width:10rem" pSortableColumn="employeePretty" pResizableColumn>
          {{ 'EMPLOYEE.EMPLOYEE' | translate }}
          <p-sortIcon field="employeePretty"></p-sortIcon>
          <p-columnFilter type="text" field="employeePretty" display="menu" [showOperator]="false"></p-columnFilter>
        </th>

      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr [pSelectableRow]="item" (dblclick)="onRowDblClick($event, item.treeNode)">
        <td *showIfDebugMode>{{ item.id }}</td>
        <td>{{ item.locationPretty }}</td>
        <td>{{ item.dateFromFormatted }}</td>
        <td>{{ item.dateUntilFormatted }}</td>
        <td>{{ item.status }}</td>
        <td>{{ item.employeePretty }}</td>
      </tr>
    </ng-template>
    <ng-template id="buttonCreate" pTemplate="paginatorleft">
      <button mat-raised-button color="primary" (click)="createNew()" *hasPermission="Permission.TOOL_CREATE">
        <mat-icon>add</mat-icon>
        {{ 'CREATE' | translate }}
      </button>
      <button mat-raised-button [disabled]="!selectedItem" (click)="delete()" class="mx-1"
              *hasPermission="Permission.TOOL_DELETE">
        <mat-icon>clear</mat-icon>
        {{ 'DELETE.DELETE' | translate }}
      </button>
    </ng-template>
  </p-table>
</div>
