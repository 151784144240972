import {Component, inject, OnDestroy, OnInit, signal} from '@angular/core';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {AppStateService} from '../../../service/app-state.service';
import {NodeType} from '../../../constants/node-type';
import {WimTreeNode} from '../../../ model/wim-tree.node';
import {ToolLocationService} from '../../../service/tool-location.service';
import {ConsoleLoggerService} from '../../../service/console-logger.service';
import {CantaaMessageService} from '../../../service/cantaa-message.service';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {ToolLocationDetail} from '../../../ model/tool-location-detail.model';
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatButton} from '@angular/material/button';
import {TableModule} from 'primeng/table';
import {SalutationService} from '../../../service/salutation.service';
import {SalutationsListItem} from '../../../ model/salutations-list-item.model';
import {MatOption} from '@angular/material/autocomplete';
import {MatSelect} from '@angular/material/select';
import {ToolLocationTypeListItem} from '../../../ model/tool-location-type-list-item.model';
import {LocationTypeService} from '../../../service/location-type.service';
import {RouteHelper} from '../../../service/helper/route-helper';
import {ActivatedRoute, Router} from '@angular/router';
import {Permission} from '../../../constants/Permission';
import {HasPermissionDirective} from '../../../directive/has-permission.directive';
import {LoggedUserService} from '../../../service/logged-user.service';
import {ToolLocationDocumentService} from "../../../service/tool-location-document.service";
import {TranslateModule} from "@ngx-translate/core";
import {DropdownItemDto} from '../../../ model/dropdown-item.model';
import {FormErrorHelper} from '../../../service/helper/form-error-helper';
import {ShowIfDebugMode} from "../../../directive/show-if-debug.directive";
import {CantaaLocalizationService} from "../../../service/cantaa-localization.service";

@Component({
  selector: 'wim-location-detail',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    MatButton,
    TableModule,
    MatOption,
    MatSelect,
    HasPermissionDirective,
    TranslateModule,
    MatError,
    ShowIfDebugMode
  ],
  templateUrl: './tool-location-detail.component.html',
  styleUrl: './tool-location-detail.component.scss'
})
export class ToolLocationDetailComponent implements OnInit, OnDestroy {
  protected readonly Permission = Permission;
  protected readonly FormErrorHelper = FormErrorHelper;

  private localization = inject(CantaaLocalizationService);

  onSelectNodeSubscription!: Subscription;
  salutations: SalutationsListItem[] = [];
  toolLocationTypes: ToolLocationTypeListItem[] = [];
  toolLocations: DropdownItemDto[] = [];
  labelName1 = signal('');
  labelName2 = signal('');

  detailForm = this.fb.group({
    id: this.fb.control<number | null>({ value: null, disabled: true }),
    rfid: (''),
    name1: this.fb.control<string | null>(null, Validators.required),
    name2: this.fb.control<string | null>(null),
    locationType: this.fb.control<ToolLocationTypeListItem | null>(null, Validators.required),
    salutation: this.fb.control<SalutationsListItem | null>(null),
    street: (''),
    city: (''),
    countryCode: (''),
    poBox: (''),
    zipCode: (''),
    phone: (''),
    email: this.fb.control<string | null>(null, Validators.email),
    locationNumber: this.fb.control<number | null>(null),
    defaultLocation: this.fb.control<DropdownItemDto | null>(null),
    parentId: this.fb.control<number | null>(null),
    parentRfid: ({ value: '', disabled: true }),
    parentLocationPretty: ({ value: '', disabled: true }),
  });

  constructor(private fb: FormBuilder,
              private appStateService: AppStateService,
              private toolLocationService: ToolLocationService,
              private messageService: CantaaMessageService,
              private log: ConsoleLoggerService,
              private errorHandler: CantaaErrorHandlerService,
              private salutationService: SalutationService,
              private locationTypeService: LocationTypeService,
              private route: ActivatedRoute,
              private router: Router,
              protected toolLocationDocumentService: ToolLocationDocumentService,
              private loggedUserService: LoggedUserService) {
  }

  async ngOnInit() {
    this.salutations = await this.fetchSalutations();
    this.toolLocationTypes = await this.fetchLocationTypes();
    this.toolLocations = await this.fetchLocations();

    if (RouteHelper.isInNewState(this.route)) {
      await this.initFormForNewEntry();
    }

    this.onSelectNodeSubscription = this.appStateService.getSelectedNode$()
      .subscribe(async selectedNode => {
        if (!selectedNode.id || selectedNode.nodeType != NodeType.TOOL_LOCATION) {
          return;
        }
        await this.initDetail(selectedNode);
      });

    if (!this.loggedUserService.hasPermission(Permission.LOCATION_EDIT)) {
      this.detailForm.disable();
    }
  }

  private async initFormForNewEntry() {
  }

  private async initDetail(selectedNode: WimTreeNode) {
    const detail = await this.toolLocationService.getToolLocationDetailById(selectedNode.id!);
    this.setDetailToForm(detail);
  }

  private setDetailToForm(detail: ToolLocationDetail) {
    let salutation = this.salutations.find(s => s.id === detail.salutationId) ?? null;
    let locationType = this.toolLocationTypes.find(t => t.id === detail.locationTypeId) ?? null;
    let defaultLocation = this.toolLocations.find(t => t.id === detail.defaultLocationId) ?? null;
    this.onLocationTypeChange(locationType);

    this.detailForm.reset(
      {
        id: detail.id,
        rfid: detail.rfid,
        name1: detail.name1,
        name2: detail.name2,
        locationType: locationType,
        salutation: salutation,
        street: detail.street,
        city: detail.city,
        countryCode: detail.countryCode,
        poBox: detail.poBox,
        zipCode: detail.zipCode,
        phone: detail.phone,
        email: detail.email,
        defaultLocation: defaultLocation,
        locationNumber: detail.locationNumber,
        parentId: detail.parentId,
        parentRfid: detail.parentRfid,
        parentLocationPretty: detail.parentLocationPretty
      }
    )

    if (detail.id !== null) {
      this.toolLocationDocumentService.getDefaultPhotoScaled2ImgEleId(detail.id, 'toollocation_icon',150,150);
    }
  }

  private async fetchSalutations() {
    try {
      return await this.salutationService.getSalutations();
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  private async fetchLocationTypes() {
    try {
      return await this.locationTypeService.getToolLocationTypes();
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  private async fetchLocations() {
    try {
      return await this.toolLocationService.getToolLocationsForDropdown();
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  ngOnDestroy(): void {
    this.onSelectNodeSubscription?.unsubscribe();
  }

  async onSave() {
    this.log.debug('on save', this.detailForm.value);

    const detail = {
      id: this.detailForm.getRawValue().id,
      rfid: this.detailForm.getRawValue().rfid,
      name1: this.detailForm.getRawValue().name1,
      name2: this.detailForm.getRawValue().name2,
      locationTypeId: this.detailForm.getRawValue().locationType?.id,
      salutationId: this.detailForm.getRawValue().salutation?.id,
      street: this.detailForm.getRawValue().street,
      city: this.detailForm.getRawValue().city,
      countryCode: this.detailForm.getRawValue().countryCode,
      poBox: this.detailForm.getRawValue().poBox,
      zipCode: this.detailForm.getRawValue().zipCode,
      phone: this.detailForm.getRawValue().phone,
      email: this.detailForm.getRawValue().email,
      locationNumber: this.detailForm.getRawValue().locationNumber,
      defaultLocationId: this.detailForm.getRawValue().defaultLocation?.id
    } as ToolLocationDetail;

    try {
      await this.toolLocationService.saveToolLocationDetail(detail);
      this.messageService.success('SAVE.SUCCESS');

      if (RouteHelper.isInNewState(this.route)) {
        await RouteHelper.navigateToGrid(this.route, this.router);
      }
    } catch (e) {
      this.errorHandler.handleFormError(this.detailForm, e, 'SAVE.FAILED');
    }
  }

  async onCancel() {
    if (RouteHelper.isInNewState(this.route)) {
      this.detailForm.reset();
    } else {
      let selectedNodeValue = this.appStateService.selectedNodeValue;
      await this.initDetail(selectedNodeValue);
    }
  }

  onLocationTypeChange(locationType: ToolLocationTypeListItem | null) {
    if ("EMPLOYEE" === locationType?.code) {
      this.labelName1.set(this.localization.instant('FIRSTNAME'));
      this.labelName2.set(this.localization.instant('LASTNAME'));
      this.detailForm.controls.salutation.enable();
      this.detailForm.controls.phone.enable();
      this.detailForm.controls.email.enable();
      this.detailForm.controls.poBox.enable();
      this.detailForm.controls.locationNumber.enable();
      this.detailForm.controls.defaultLocation.enable();
    } else {
      this.labelName1.set(this.localization.instant('DESIGNATION'));
      this.labelName2.set(this.localization.instant('CODE'));
      this.detailForm.controls.salutation.disable();
      this.detailForm.controls.phone.disable();
      this.detailForm.controls.email.disable();
      this.detailForm.controls.poBox.disable();
      this.detailForm.controls.locationNumber.disable();
      this.detailForm.controls.defaultLocation.disable();
    }

  }

}
