<div class="container ">

  <form [formGroup]="detailForm">

    <div *showIfDebugMode class="row">

      <div class="col-3 flex">
        <mat-form-field class="w-100">
          <mat-label>Id</mat-label>
          <input id="id" matInput formControlName="id">
        </mat-form-field>
      </div>

    </div>

    <div class="row">

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'CREATED_ON' | translate }}</mat-label>
          <input id="createdOn" matInput formControlName="createdOn">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'TYPE_CODE' | translate }}</mat-label>
          <input id="transactionTypeCode" matInput formControlName="transactionTypeCode">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'TYPE_NAME' | translate }}</mat-label>
          <input id="transactionTypeName" matInput formControlName="transactionTypeName">
        </mat-form-field>
      </div>

    </div>

    <div class="row">

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'FROM' | translate }} id</mat-label>
          <input id="fromId" matInput formControlName="fromId">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'FROM' | translate }} {{ 'NAME' | translate }} 1</mat-label>
          <input id="fromName1" matInput formControlName="fromName1">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'FROM' | translate }} {{ 'NAME' | translate }} 2</mat-label>
          <input id="fromName2" matInput formControlName="fromName2">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'FROM' | translate }} {{ 'ADDRESS_TYPE' | translate }}</mat-label>
          <input id="fromLocationType" matInput formControlName="fromLocationType">
        </mat-form-field>
      </div>

    </div>

    <div class="row">

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'TO' | translate }} id</mat-label>
          <input id="toId" matInput formControlName="toId">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'TO' | translate }} {{ 'NAME' | translate }} 1</mat-label>
          <input id="toName1" matInput formControlName="toName1">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'TO' | translate }} {{ 'NAME' | translate }} 2</mat-label>
          <input id="toName2" matInput formControlName="toName2">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'TO' | translate }} {{ 'ADDRESS_TYPE' | translate }}</mat-label>
          <input id="toLocationType" matInput formControlName="toLocationType">
        </mat-form-field>
      </div>

    </div>

    <div class="row">

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'MOVE' | translate }} id</mat-label>
          <input id="toMovedId" matInput formControlName="movedLocationId">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'MOVE' | translate }} {{ 'NAME' | translate }} 1</mat-label>
          <input id="toMovedName1" matInput formControlName="movedLocationName1">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'MOVE' | translate }} {{ 'NAME' | translate }} 2</mat-label>
          <input id="toMovedName2" matInput formControlName="movedLocationName2">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'MOVE' | translate }} {{ 'ADDRESS_TYPE' | translate }}</mat-label>
          <input id="toMovedLocationType" matInput formControlName="movedLocationType">
        </mat-form-field>
      </div>

    </div>

  </form>
</div>
