<div class="card p-input-filled full-height">
  <p-table
    #dt
    dataKey="id"
    [value]="gridItems"
    [lazy]="true"
    selectionMode="single"
    [(selection)]="selectedItem"
    [resizableColumns]="true" columnResizeMode="expand"
    (onLazyLoad)="fetchDataViaTableComponent($event)"
    [paginator]="true"
    [rows]="gridMeta.lazyLoadMeta?.rows ?? 20"
    [rowsPerPageOptions]="[10, 20, 50]"
    [paginatorDropdownAppendTo]="'body'"
    [totalRecords]="totalRecords"
    [sortMode]="'multiple'"
    [multiSortMeta]="gridMeta.lazyLoadMeta?.multiSortMeta"
    [filters]="gridMeta.filters!"
    [scrollable]="true" scrollHeight="var(--explorer-grid-height)"
    [tableStyle]="{ 'min-width': '50rem' }"
    styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
  >
    <ng-template pTemplate="header">
      <tr>
        <th *showIfDebugMode id="id" style="min-width:8rem" pSortableColumn="id" pResizableColumn>
          Id
          <p-sortIcon field="id"></p-sortIcon>
          <p-columnFilter type="numeric" field="id" display="menu" [showOperator]="false"></p-columnFilter>
        </th>

        <th id="name" style="min-width:10rem" pSortableColumn="name" pResizableColumn>
          {{ 'NAME' | translate }}
          <p-sortIcon field="name"></p-sortIcon>
          <p-columnFilter type="text" field="name" display="menu" [showOperator]="false"></p-columnFilter>
        </th>

        <th id="firstInspectionMonth" style="min-width:8rem" pSortableColumn="firstInspectionMonth" pResizableColumn>
          {{ 'INSPECTION.FIRST_INSPECTION_AFTER_X_MONTHS' | translate }}
          <p-sortIcon field="firstInspectionMonth"></p-sortIcon>
          <p-columnFilter type="numeric" field="firstInspectionMonth" display="menu" [showOperator]="false"></p-columnFilter>
        </th>

        <th id="nextInspectionMonth" style="min-width:8rem" pSortableColumn="nextInspectionMonth" pResizableColumn>
          {{ 'INSPECTION.NEXT_INSPECTION_AFTER_X_MONTHS' | translate }}
          <p-sortIcon field="nextInspectionMonth"></p-sortIcon>
          <p-columnFilter type="numeric" field="nextInspectionMonth" display="menu" [showOperator]="false"></p-columnFilter>
        </th>

      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr [pSelectableRow]="item" (dblclick)="onRowDblClick($event, item.treeNode)">
        <td *showIfDebugMode>{{ item.id }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.firstInspectionMonth }}</td>
        <td>{{ item.nextInspectionMonth }}</td>
      </tr>
    </ng-template>
    <ng-template id="buttonCreate" pTemplate="paginatorleft">
      <button mat-raised-button color="primary" class="m-1" (click)="createNew()"
              *hasPermission="Permission.INSPECTION_TYPE_CREATE">
        <mat-icon>add</mat-icon>
        {{ 'CREATE' | translate }}
      </button>
      <button mat-raised-button [disabled]="selectedItem === null" (click)="delete()"
              *hasPermission="Permission.INSPECTION_TYPE_DELETE">
        <mat-icon>clear</mat-icon>
        {{ 'DELETE.DELETE' | translate  }}
      </button>
    </ng-template>
  </p-table>
</div>
