import { Component, OnDestroy, OnInit } from '@angular/core';
import { HasPermissionDirective } from '../../../directive/has-permission.directive';
import { MatButton } from '@angular/material/button';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatFormField, MatHint, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ToolCategoryListItem } from '../../../ model/tool-category-list-item.model';
import { ManufacturerListItem } from '../../../ model/manufacturer.model';
import { Subscription } from 'rxjs';
import { AppStateService } from '../../../service/app-state.service';
import { CantaaErrorHandlerService } from '../../../service/cantaa-error-handler.service';
import { ToolDocumentService } from '../../../service/tool-document.service';
import { NodeType } from '../../../constants/node-type';
import { WimTreeNode } from '../../../ model/wim-tree.node';
import { InspectionService } from '../../../service/inspection.service';
import { DebugComponent } from '../../debug/debug.component';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'wim-tool-detail-inspection',
  standalone: true,
  templateUrl: './tool-detail-inspection.component.html',
  styleUrl: './tool-detail-inspection.component.scss',
  imports: [
    HasPermissionDirective,
    MatButton,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatFormField,
    MatHint,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    MatSuffix,
    ReactiveFormsModule,
    TranslateModule,
    DebugComponent,
    JsonPipe
  ]
})
export class ToolDetailInspectionComponent implements OnInit, OnDestroy {

  detailForm = this.fb.group({
    id: this.fb.control<number | null>({ value: null, disabled: true }),
    name: (''),
    firstInspectionMonth: this.fb.control<number | null>({ value: null, disabled: true }),
    nextInspectionMonth: this.fb.control<number | null>({ value: null, disabled: true }),
    dueDate: (''),
    actualInspectionDate: (''),
  });

  onSelectNodeSubscription!: Subscription;
  categories: ToolCategoryListItem[] = [];
  manufacturers: ManufacturerListItem[] = [];

  constructor(private fb: FormBuilder,
              private appStateService: AppStateService,
              private inspectionService: InspectionService,
              private errorHandler: CantaaErrorHandlerService,
              protected toolDocumentService: ToolDocumentService) {
  }

  async ngOnInit() {
    this.onSelectNodeSubscription = this.appStateService.getSelectedNode$()
      .subscribe(async selectedNode => {
        if (!selectedNode.id || selectedNode.nodeType != NodeType.TOOL) {
          return;
        }
        await this.initFormForEdit(selectedNode);
      });

    this.detailForm.disable();
  }

  private async initFormForEdit(selectedNode: WimTreeNode) {

    const detail = await this.findFirstPendingToolInspection(selectedNode.id!);

    if (!detail) {
      this.detailForm.reset();
      return;
    }

    this.detailForm.setValue(
      {
        id: detail.id,
        name: detail.name,
        firstInspectionMonth: detail.firstInspectionMonth,
        nextInspectionMonth: detail.nextInspectionMonth,
        dueDate: detail.dueDateFormatted,
        actualInspectionDate: detail.actualInspectionDate
      });
  }

  private async findFirstPendingToolInspection(toolId: number) {
    try {
      return await this.inspectionService.findFirstPendingToolInspection(toolId);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  ngOnDestroy(): void {
    this.onSelectNodeSubscription?.unsubscribe();
  }

}
