import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTab, MatTabContent, MatTabGroup } from '@angular/material/tabs';
import { ToolDetail } from '../../tool/tool-detail/tool-detail.component';
import { ToolDetailDocumentsComponent } from '../../tool/tool-detail-documents/tool-detail-documents.component';
import { ToolDetailInventoryComponent } from '../../tool/tool-detail-inventory/tool-detail-inventory.component';
import { ToolLocationDetailComponent } from '../tool-location-detail/tool-location-detail.component';
import { ToolLocationInventoryComponent } from '../tool-location-inventory/tool-location-inventory.component';
import { ToolLocationService } from '../../../service/tool-location.service';
import { NodeType } from '../../../constants/node-type';
import { Subscription } from 'rxjs';
import { AppStateService } from '../../../service/app-state.service';
import { CantaaErrorHandlerService } from '../../../service/cantaa-error-handler.service';
import { VisibleTabsModel } from '../../../ model/visible-tabs.model';
import { WimTreeNode } from '../../../ model/wim-tree.node';
import {
  QualificationAssignmentLocationComponent
} from '../../qualification/qualification-assignment-location/qualification-assignment-location.component';
import {ToolLocationDocumentsComponent} from "../tool-location-documents/tool-location-documents.component";
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'wim-tool-location-tab-detail',
  standalone: true,
  imports: [
    MatTab,
    MatTabContent,
    MatTabGroup,
    ToolDetail,
    ToolDetailDocumentsComponent,
    ToolDetailInventoryComponent,
    ToolLocationDetailComponent,
    ToolLocationInventoryComponent,
    QualificationAssignmentLocationComponent,
    ToolLocationDocumentsComponent,
    TranslateModule
  ],
  templateUrl: './tool-location-tab-detail.component.html',
  styleUrl: './tool-location-tab-detail.component.scss'
})
export class ToolLocationTabDetailComponent implements OnInit, OnDestroy {
  onSelectNodeSubscription!: Subscription;
  visibleTabs: VisibleTabsModel | null = null;

  constructor(private appStateService: AppStateService,
              private toolLocationService: ToolLocationService,
              private errorHandler: CantaaErrorHandlerService) {
  }

  ngOnInit(): void {
    this.onSelectNodeSubscription = this.appStateService.getSelectedNode$()
      .subscribe(async selectedNode => {
        if (!selectedNode.id || selectedNode.nodeType != NodeType.TOOL_LOCATION) {
          return;
        }
        this.visibleTabs = await this.fetchToolLocationVisibleTabs(selectedNode);
      });
  }

  private async fetchToolLocationVisibleTabs(selectedNode: WimTreeNode) {
    try {
      return await this.toolLocationService.getToolLocationVisibleTabs(selectedNode.id!);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  ngOnDestroy(): void {
    this.onSelectNodeSubscription?.unsubscribe();
  }

}
