import {AfterViewInit, Component, ElementRef, inject, viewChild} from '@angular/core';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatButton, MatIconButton, MatMiniFabButton} from '@angular/material/button';
import {MatInput} from '@angular/material/input';
import {FormBuilder, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {Table, TableLazyLoadEvent, TableModule} from 'primeng/table';
import {ChipsModule} from 'primeng/chips';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import {CantaaMessageService} from '../../../service/cantaa-message.service';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {GridHelperService} from '../../../service/helper/grid-helper.service';
import {FocusHelper} from '../../../service/helper/focus-helper';
import {GridRequest} from '../../../ model/grid-request.model';
import {DocumentTypeService} from '../../../service/document-type.service';
import {DocumentTypeListItem} from '../../../ model/document-type-list-item.model';
import {Permission} from '../../../constants/Permission';
import {HasPermissionDirective} from '../../../directive/has-permission.directive';
import {CantaaGridMeta} from "../../../ model/GridMeta";
import {GridMetaService} from "../../../service/grid-meta.service";

@Component({
  selector: 'wim-document-type-grid',
  standalone: true,
  imports: [
    MatButton,
    TableModule,
    FormsModule,
    ChipsModule,
    ButtonModule,
    RippleModule,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatMiniFabButton,
    MatIconModule,
    MatIconButton,
    HasPermissionDirective
  ],
  templateUrl: './document-type-grid.component.html',
  styleUrl: './document-type-grid.component.scss'
})
export class DocumentTypeGridComponent implements AfterViewInit {
  dataTable = viewChild.required<Table>('dt');
  protected readonly Permission = Permission;
  readonly gridName = 'DOCUMENT_TYPE_GRID';

  private gridMetaService = inject(GridMetaService);
  private fb = inject(FormBuilder);
  private messageService = inject(CantaaMessageService);
  private errorHandler = inject(CantaaErrorHandlerService);
  private el = inject(ElementRef);
  private gridHelperService = inject(GridHelperService);
  private documentTypeService = inject(DocumentTypeService);

  documentTypes: DocumentTypeListItem[] = [];
  totalRecords: number = 0;
  editMode = false;

  gridMeta!: CantaaGridMeta;

  createNewForm = this.fb.group({
    code: ['', Validators.required],
    name: ['', Validators.required],
  });

  clonedItems: { [s: number]: DocumentTypeListItem } = {};

  constructor() {
    this.initGridMeta();
  }

  private initGridMeta() {
    const defaultSortMeta = [{field: 'name', order: 1}];
    this.gridMeta = this.gridMetaService.getGridMeta(this.gridName, defaultSortMeta);
  }

  async ngAfterViewInit() {
    await this.reloadData();
  }

  onRowEditInit(documentType: DocumentTypeListItem) {
    this.clonedItems[documentType.id] = { ...documentType };
    this.editMode = true;
  }

  async onRowEditSave(documentType: DocumentTypeListItem) {
    await this.save(documentType);
    this.editMode = false;
    await this.reloadData();
  }

  private async save(documentType: DocumentTypeListItem) {
    try {
      await this.documentTypeService.saveDocumentType(documentType);
      this.messageService.success('SAVE.SUCCESS');
    } catch (e) {
      this.errorHandler.handleError(e, 'SAVE.FAILED');
    }
  }

  onRowEditCancel(documentType: DocumentTypeListItem, index: number) {
    this.documentTypes[index] = this.clonedItems[documentType.id];
    delete this.clonedItems[documentType.id];
    this.editMode = false;
  }

  async onCreateNew() {
    const documentType = {
      code: this.createNewForm.value.code,
      name: this.createNewForm.value.name
    } as DocumentTypeListItem;

    await this.save(documentType);
    await this.reloadData();
    this.createNewForm.reset();
    FocusHelper.focusFormField(this.el, 'code');
  }

  onCancel() {
    this.createNewForm.reset();
  }

  async onUpdateStatus(documentType: DocumentTypeListItem) {
    try {
      await this.documentTypeService.updateDocumentTypeStatus(documentType.id, !documentType.active);
      this.messageService.success('UPDATE.SUCCESS');
      await this.reloadData();
    } catch (e) {
      this.errorHandler.handleError(e, 'UPDATE.FAILED');
    }
  }

  async fetchDataViaTableComponent(lazyLoadMeta: TableLazyLoadEvent) {
    this.gridMetaService.setGridMeta(this.gridName, {lazyLoadMeta});
    let gridRequest = this.gridHelperService.createGridRequest(lazyLoadMeta);
    await this.fetchDAta(gridRequest);
  }

  async reloadData() {
    let lazyLoadMetadata = this.dataTable().createLazyLoadMetadata();
    let gridRequest = this.gridHelperService.createGridRequest(lazyLoadMetadata);
    await this.fetchDAta(gridRequest);
  }

  async fetchDAta(gridRequest: GridRequest) {
    try {
      const collection = await this.documentTypeService.getPagedDocumentTypes(gridRequest);
      this.documentTypes = collection.items;
      this.totalRecords = collection.totalNumberOfItems;
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
    }
  }

  getTdClass(item: any) {
    return item.active ? "" : 'strikethrough';
  }

}
