import {Component, inject} from '@angular/core';
import {BaseNodeGridComponent} from '../../base-components/base-node-grid.component';
import {TreeHelperService} from '../../../service/helper/tree-helper.service';
import {WimTreeNode} from '../../../ model/wim-tree.node';
import {NodeType} from '../../../constants/node-type';
import {TableModule} from 'primeng/table';
import {ToolCategoryListItem} from '../../../ model/tool-category-list-item.model';
import {ToolCategoryService} from '../../../service/tool-category.service';
import {SharedModule, TreeNode} from 'primeng/api';
import {WimIcons} from '../../../constants/wim-icons';
import {ButtonModule} from 'primeng/button';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {ToolConstants} from '../../../constants/tool-constants';
import {MatIcon} from '@angular/material/icon';
import {MatButton} from '@angular/material/button';
import {GridRequest} from '../../../ model/grid-request.model';
import {RouteType} from '../../../constants/route-type';
import {CantaaMessageService} from '../../../service/cantaa-message.service';
import {Permission} from '../../../constants/Permission';
import {HasPermissionDirective} from '../../../directive/has-permission.directive';
import {UuidHelperService} from '../../../service/helper/uuid-helper.service';
import {TranslateModule} from '@ngx-translate/core';
import {ShowIfDebugMode} from "../../../directive/show-if-debug.directive";

@Component({
  selector: 'wim-tool-category-grid',
  standalone: true,
  imports: [
    SharedModule,
    TableModule,
    ButtonModule,
    MatIcon,
    MatButton,
    HasPermissionDirective,
    TranslateModule,
    ShowIfDebugMode,
  ],
  templateUrl: './tool-category-grid.component.html',
  styleUrl: './tool-category-grid.component.scss'
})
export class ToolCategoryGrid extends BaseNodeGridComponent<ToolCategoryListItem> {
  protected readonly Permission = Permission;
  protected override nodeType = NodeType.TOOL_PARENT_CATEGORY_RELATION;
  buttonCreateDisabled = true;

  private toolCategoryService = inject(ToolCategoryService);
  private messageService = inject(CantaaMessageService);
  private treeHelperService = inject(TreeHelperService);
  private errorHandler = inject(CantaaErrorHandlerService);

  override needReloadAfterNodeChanged(selectedNode: WimTreeNode): boolean {
    if (
      // selected node is not empty
      selectedNode.key
      // last selected node and currentSelected node are differ
      && (this.lastSelectedNode == null || this.lastSelectedNode.key != selectedNode.key)
      && (selectedNode.nodeType === NodeType.TOOL_SUB_CATEGORY_RELATION || selectedNode.nodeType === NodeType.TOOL_PARENT_CATEGORY_RELATION)
    ) {
      return true;
    }
    return false;
  }

  override async onNodeChange(selectedNode: WimTreeNode) {
    await super.onNodeChange(selectedNode);
    this.buttonCreateDisabled = selectedNode.nodeType === NodeType.TOOL_PARENT_CATEGORY_RELATION;
  }

  override async fetchGridItems(gridRequest: GridRequest, selectedNode: WimTreeNode) {
    try {
      return await this.toolCategoryService.getToolCategories(selectedNode, gridRequest);
    } catch (e) {
      this.errorHandler.handleError(e, 'FAILED_TO_FETCH');
      throw e;
    }
  }

  override mapTreeItems(selectedNode: WimTreeNode, items: ToolCategoryListItem[]): WimTreeNode[] {
    return items
      .map<WimTreeNode>(listItem => {

        let treeNode = {
          id: listItem.id,
          key: UuidHelperService.generateUuid(),
          label: listItem.name,
          data: `${listItem.id};${listItem.levelType}`,
          icon: WimIcons.TOOL_CATEGORY_ICON,
          nodeType: NodeType.TOOL_CATEGORY,
          route: RouteType.CATEGORY,
          children: [] as TreeNode[],
          leaf: false,
          parent: selectedNode
        };
        listItem.treeNode = treeNode;

        treeNode.children = [];
        if (listItem.levelType != ToolConstants.LEVEL_TYPE.TOOL) {
          treeNode.children.push(this.treeHelperService.createToolSubCategoryRelationsTreeItem(treeNode));
        }
        if (listItem.levelType != 'MAINGROUP') {
          treeNode.children.push(this.treeHelperService.createToolParentCategoryRelationsTreeItem(treeNode));
        }
        treeNode.children.push(this.treeHelperService.createToolRelationsTreeItem(treeNode));
        treeNode.children.push(this.treeHelperService.createReservationRelationsTreeItem(treeNode));

        return treeNode;
      });
  }

  async delete() {
    try {
      await this.toolCategoryService.deleteToolCategory(this.selectedItem);
      this.messageService.success('DELETE.SUCCESS');
      await this.reFetchData();
    } catch (e) {
      this.errorHandler.handleError(e, 'DELETE.FAILED');
      throw e;
    }
  }

}


