import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { HttpClientHelper } from './http-client-helper.service';
import { LoggedUser } from '../ model/logged-user.model';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { ConsoleLoggerService } from './console-logger.service';


@Injectable({
  providedIn: 'root'
})
export class LoggedUserService {

  private anonymous = {
    username: 'anonymous',
    name: '',
    email: '',
    tenant: 'string',
    defaultLocationId: null,
    authorities: []
  }

  private loggedUserSubject$ = new BehaviorSubject<LoggedUser>(this.anonymous);
  public loggedUser$ = this.loggedUserSubject$.asObservable();

  constructor(private http: HttpClient,
              private httpClientHelper: HttpClientHelper,
              protected router: Router,
              private log: ConsoleLoggerService) {
  }

  get loggedUser() {
    return this.loggedUserSubject$.value;
  }

  hasPermission(permission: string): boolean {
    return this.loggedUser?.authorities?.includes(permission);
  }

  hasAnyPermission(permissions: string[]): boolean {
    if (!this.loggedUser) {
      return false;
    }
    return permissions.some(element => {
      // console.log(element + ' el => ' + this.loggedUser.authorities.includes(element));
      return this.loggedUser.authorities.includes(element);
    });
  }

  // fetchLoggedUser() {
  //   const params = this.httpClientHelper.createHttpParams();
  //   let url = environment.apiUrl + `/logged-user`;
  //   return this.http.get<LoggedUser>(url, { params: params })
  //     .subscribe(loggedUser => {
  //       console.log('fetched logged user from BE:', loggedUser);
  //       this.loggedUserSubject$.next(loggedUser);
  //     });
  // }

  async fetchLoggedUserAsync() {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/logged-user`;
    try {
      const loggedUser = await firstValueFrom(this.http.get<LoggedUser>(url, { params: params }));
      this.loggedUserSubject$.next(loggedUser);
      this.log.debug('fetched logged user from BE:', loggedUser);
    } catch (error) {
      const errorMessage = 'Fetching logged user from backend failed';
      this.log.error(errorMessage + ':', error);
      await this.router.navigateByUrl('/error', { state: { errorMessage: errorMessage } });
    }

  }

}
