import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { HttpClientHelper } from './http-client-helper.service';
import { firstValueFrom, tap } from 'rxjs';
import { ConsoleLoggerService } from './console-logger.service';
import { AppInfoModel } from '../ model/app-info.model';


@Injectable({
  providedIn: 'root'
})
export class AppInfoService {

  constructor(private http: HttpClient,
              private httpClientHelper: HttpClientHelper,
              private log: ConsoleLoggerService) {
  }

  getAppInfo() {
    const params = this.httpClientHelper.createHttpParams();
    let url = environment.apiUrl + `/app-info`;
    return firstValueFrom(this.http.get<AppInfoModel>(url, { params: params })
      .pipe(tap(r => this.log.debug('app info: ', r))));
  }

}
