import { CantaaLocale } from '../ model/CantaaLocale';

export class LocaleHelper {

  public static parseLocale(languageMarket: string): CantaaLocale {
    var fields = languageMarket.split('-');

    var lang = fields[0];
    var market = fields[1];
    return {
      language: lang,
      market: market
    } as CantaaLocale;
  }

}
